<template>
	<div class="contentmain">
		<el-card class="box-card">
			<el-form :model="realNameForm" ref="realNameForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="姓名：" prop="realName" :rules="[{ required: true, message: '请输入您的姓名' }]">
					<el-input v-model="realNameForm.realName" placeholder="请输入您的姓名"></el-input>
				</el-form-item>
				<el-form-item label="身份证号：" prop="idNumber" :rules="[{ required: true, message: '请输入您的身份证号' }]">
					<el-input v-model="realNameForm.idNumber" placeholder="请输入您的身份证号"></el-input>
					<div class="beizhu font14">*请确认个人信息，验证后不可更改</div>
				</el-form-item>
				<el-form-item>
					<el-button class="tg" type="primary" @click="$router.push('/index')">跳过</el-button>
					<!-- @click="submitForm('infoForm')" -->
					<el-button type="primary" @click="confirm('realNameForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-card>
		<!-- 您的实名认证未通过弹窗 -->
		<!-- <el-dialog class=""  :visible.sync="centerDialogVisible" width="800px" center :show-close="false" :modal-append-to-body="false">
			<div class="registersuccess">
				<span class="closeicon" @click="closebox()">
					<i class="el-icon-error"></i>
				</span>
				<div class="gou_img">
					<img src="../../assets/images/common/position-nodata.png" >
				</div>
				<div class="congratulations font18">
					您的实名认证未通过
				</div>
			</div>
		</el-dialog> -->

	</div>
</template>

<script>
import Bus from '../../common/bus.js'
export default {
	data() {
		return {
			realNameForm: {
				realName: '',
				idNumber:'',
			},
			centerDialogVisible: true
		};
	},
	created() {
		this.getUserInfo()
	},
	mounted() {},
	methods: {
		getUserInfo(){
			this.$api.getUserInfo('get').then(res=>{
				this.realNameForm.realName = res.data.userAuthentication.realName
				this.realNameForm.idNumber = res.data.userAuthentication.idNumber
			})
		},
		confirm(formName){
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$api.realName('post', this.realNameForm).then((res) => {
            this.$message({
              message: res.code == 10200 ? "实名认证通过" : res.message,
              type: res.code == 10200 ? "success" : "error",
            });
            if(res.code === 10200){
              this.$router.push('/index');
            }
          }).catch(() => {
            Bus.$emit('smfailstuts', true)
          })
        } else {
          return false;
        }
      });
		},
		closebox(){
			this.centerDialogVisible=false;
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					alert('submit!');
				} else {
					console.log('操作失败!');
					return false;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.box-card {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 45px 0 160px 0;
	margin-top: 20px;
	margin-bottom: 150px;
	border-radius: 0;


	.demo-ruleForm {
		margin-top: 50px;
		.beizhu{
			color: #FF4444;
			padding: 0 15px;
		}

		/deep/ .el-input__inner {
			border-radius: 0px;
			width: 500px;
		}
		/deep/ .el-button {
			border-radius: 0px;
			width: 240px;
			margin-top: 40px;
			background: #00bcff;
			border: none;
		}
		.tg{
			border-radius: 0px;
			width: 240px;
			margin-top: 40px;
			background: #ffffff;
			border: 1px solid #00bcff;
			color: #00bcff;
			margin-right: 10px;
		}
	}
}
.registersuccess{
	padding: 84px 0;
	position: relative;
	.gou_img{
		text-align: center;
		img{
			width: 257px;
			height: 216px;
		}
	}
	.congratulations{
		text-align: center;
		color: #111111;
	}
	.closeicon{
		position: absolute;
		top:-40px;
		right: 0;
		font-size:26px;
		color: #F3F3F3;
	}
}
</style>
